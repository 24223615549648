@import '../init';

.login {
    background-color: #ffffff;

    @include media-breakpoint-up(sm) {
        background: no-repeat center center fixed;
        background-size: cover;
        background-image: url('../../images/login_bg_low.jpg');

        @media (min-resolution: 2dppx) {
            background-image: url('../../images/login_bg.jpg');
        }
    }

    .card {
        margin-top: auto;
        margin-bottom: auto;

        @include media-breakpoint-down(sm) {
            border: none;
            width: 100%;
            border-radius: $border-radius-lg;
            padding-top: $spacer * 2.5;
            padding-bottom: $spacer * 2.5;
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;

            .btn {
                width: 100%;
            }
        }

        @include media-breakpoint-up(sm) {
            width: 550px;
            border-radius: $border-radius-lg;
            box-shadow: $card-box-shadow;
            padding-top: ($spacer * 3.75);
            padding-bottom: $spacer * 2.5;
            padding-left: $spacer * 4;
            padding-right: $spacer * 4;
        }

        .form-group {
            label {
                font-size: $h6-font-size;
                font-weight: $font-weight-bolder;
                color: $dark;
            }

            .form-control {
                height: auto;
                border-radius: $border-radius-lg;
                padding-bottom: $spacer * 1.5;
                padding-top: $spacer * 1.5;
                padding-left: $spacer * 1.25;
                padding-right: $spacer * 1.25;

                background-color: $input-solid-bg;
                border-color: $input-solid-bg;
                @include placeholder($input-solid-placeholder-color);
                color: $input-solid-color;
                transition: $transition-input;

                &:active,
                &.active,
                &:focus,
                &.focus {
                    background-color: $input-solid-bg-focus;
                    border-color: $input-solid-bg-focus;
                    color: $input-solid-color;
                    transition: $transition-input;
                }
            }
        }

        .logo {
            width: 200px;

            svg {
                path {
                    fill: $dark-75 !important;
                }
            }
        }

        .content-img {
            min-height: 450px;
        }

        .btn {
            min-width: 250px;
        }
    }
}
