//
// Timeline
//

.timeline.timeline-1 {
    position: relative;

    .timeline-sep {
        z-index: 0;
        content: '';
        position: absolute;
        left: get($timeline-config, time-width) +
            get($timeline-config, badge-width) / 2 - 2px;
        width: 4px;
        top: 0;
        bottom: 0;
        background-color: $gray-200;
    }

    .timeline-item {
        flex-grow: 1;
        display: flex;
        margin-bottom: 1rem;
        align-items: center;

        .timeline-label {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 get($timeline-config, time-width);
            font-weight: 500;
            color: $text-muted;
        }

        .timeline-badge {
            display: flex;
            width: get($timeline-config, badge-width);
            height: get($timeline-config, badge-width);
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-radius: 50%;
            border: 4px solid white;
            position: relative;
            z-index: 0;

            .svg-icon,
            i {
                line-height: 0;
            }
        }

        .timeline-content {
            flex-grow: 1;
            font-size: 1rem;
            padding: 0 0 0 0.75rem;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
