//
// Offcanvas
//

@mixin offcanvas-base($class, $config) {
    $width: get($config, width);
    $height: get($config, height);
    $offset: 20px;

    $transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease,
        top 0.3s ease;

    .#{$class} {
        z-index: get($config, zindex);
        position: fixed;
        top: 0;
        bottom: 0;
        //overflow-y: auto;
        transition: $transition;

        @if $width != null {
            left: -($width + $offset);
            width: $width;
        }

        @if $height != null {
            height: $height;
        }

        &.#{$class}-on {
            transition: $transition;
            left: 0;
        }

        @include for-ie11 {
            transition: none !important;
        }
    }

    @if $width != null {
        // Right Direction
        .#{$class}.#{$class}-right {
            right: -($width + $offset);
            left: auto;

            &.#{$class}-on {
                transition: $transition;
                right: 0;
                left: auto;
            }
        }
    }

    @if $height != null {
        // Top Direction
        .#{$class}.#{$class}-top {
            top: -($height + $offset);
            left: 0;
            right: 0;

            &.#{$class}-on {
                transition: $transition;
                top: 0;
                left: 0;
                right: 0;
            }
        }

        // Bottom Direction
        .#{$class}.#{$class}-bottom {
            bottom: -($height + $offset);
            left: 0;
            right: 0;

            &.#{$class}-on {
                transition: $transition;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }

    // Overlay
    .#{$class}-overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        z-index: get($config, zindex) - 1;
        animation: animation-offcanvas-fade-in 0.6s ease 1;
    }

    // Animation
    @keyframes animation-offcanvas-fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

@mixin offcanvas-width($class, $width) {
    $offset: 20px;

    .#{$class} {
        width: $width;
        left: -($width + $offset);

        &.#{$class}-on {
            left: 0;
        }
    }

    // Right Direction
    .#{$class}.#{$class}-right {
        right: -($width + $offset);
        left: auto;

        &.#{$class}-on {
            right: 0;
            left: auto;
        }
    }
}

@mixin offcanvas-height($class, $height) {
    $offset: 20px;

    // Top Direction
    .#{$class}.#{$class}-top {
        top: -($height + $offset);
        left: 0;
        right: 0;

        &.#{$class}-on {
            top: 0;
            left: 0;
            right: 0;
        }
    }

    // Bottom Direction
    .#{$class}.#{$class}-bottom {
        bottom: -($height + $offset);
        left: 0;
        right: 0;

        &.#{$class}-on {
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

@mixin offcanvas-theme($class, $config) {
    .#{$class} {
        @if (has($config, bg-color)) {
            background: get($config, bg-color);
        }

        @if (has($config, shadow)) {
            box-shadow: get($config, shadow);
        }
    }

    .#{$class}-overlay {
        background: get($config, overlay-bg-color);
    }
}

@mixin offcanvas-build($class, $mode, $config) {
    @if $mode == default {
        // Default Mode
        @include offcanvas-base($class, $config);
        @include offcanvas-theme($class, $config);
    }

    @if $mode == desktop {
        // Desktop Mode
        @include media-breakpoint-up(lg) {
            @include offcanvas-base($class, $config);
            @include offcanvas-theme($class, $config);
        }
    }

    @if $mode == desktop-and-tablet {
        // Desktop & Tablet Modes
        @include media-breakpoint-up(md) {
            @include offcanvas-base($class, $config);
            @include offcanvas-theme($class, $config);
        }
    }

    @if $mode == tablet {
        // Tablet Mode
        @include media-breakpoint-between(md, lg) {
            @include offcanvas-base($class, $config);
            @include offcanvas-theme($class, $config);
        }
    }

    @if $mode == tablet-and-mobile {
        // Tablet & Mobile Modes
        @include media-breakpoint-down(md) {
            @include offcanvas-base($class, $config);
            @include offcanvas-theme($class, $config);
        }
    }

    @if $mode == mobile {
        // Mobile Mode
        @include media-breakpoint-down(sm) {
            @include offcanvas-base($class, $config);
            @include offcanvas-theme($class, $config);
        }
    }

    // Responsive Width
    @if has($config, width) {
        @media (max-width: get($config, width) + 50px) {
            .#{$class} {
                width: 90% !important;
            }
        }
    }

    // Tablet & Mobile Modes
    @include media-breakpoint-down(md) {
        [data-offcanvas-#{$class}='on'] {
            overflow: hidden !important;
        }
    }
}
