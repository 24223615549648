//
// checkbox
//

// Base
.checkbox {
    display: flex;
    align-items: center;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    transition: get($checkbox-config, transition);
    margin: 0;

    > span {
        background-color: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        @include border-radius($border-radius);

        &:after {
            content: '';
            border-color: transparent;
            border-style: solid;
            border-width: 0 2px 2px 0#{'/*rtl:ignore*/'} !important;
            transform: rotate(45deg) #{'/*rtl:ignore*/'};
            margin-top: -2px;
        }
    }

    // Hide default browser input
    > input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    // Hover & focus states
    &:hover > input:not([disabled]) ~ span,
    > input:focus ~ span {
        transition: get($checkbox-config, transition);
    }

    // Disabled state
    &.checkbox-disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    // Checked state
    > input:checked ~ span {
        transition: get($checkbox-config, transition);
        background-color: none;

        &:after {
            display: block;
        }
    }

    // Square Style
    &.checkbox-square {
        > span {
            border-radius: 0;
        }
    }

    // Circle Style
    &.checkbox-circle {
        > span {
            @include border-radius(50%);
        }
    }

    // Outline type
    &.checkbox-outline {
        > span {
            border-width: 1px;
            border-style: solid;
        }
    }

    // Outline 2x type
    &.checkbox-outline-2x {
        > span {
            border-width: 2px !important;
        }
    }
}

// Sizes
.checkbox {
    // Solid type
    @include checkbox-size(
        get($checkbox-config, types, solid, sizes, default, base),
        get($checkbox-config, types, solid, sizes, default, tick)
    );
    &.checkbox-lg {
        @include checkbox-size(
            get($checkbox-config, types, solid, sizes, lg, base),
            get($checkbox-config, types, solid, sizes, lg, tick)
        );
    }

    // Outline type
    &.checkbox-outline {
        @include checkbox-size(
            get($checkbox-config, types, outline, sizes, default, base),
            get($checkbox-config, types, outline, sizes, default, tick)
        );

        &.checkbox-lg {
            @include checkbox-size(
                get($checkbox-config, types, outline, sizes, lg, base),
                get($checkbox-config, types, outline, sizes, lg, tick)
            );
        }
    }
}

// Theme colors
.checkbox {
    // Default style
    @include checkbox-solid-theme(
        get($checkbox-config, types, solid, theme, base-color),
        $primary,
        $white
    );

    // Outline style
    &.checkbox-outline {
        @include checkbox-outline-theme(
            get($checkbox-config, types, outline, theme, base-color),
            $primary,
            $primary
        );
    }

    // Color options
    @each $name, $color in $theme-colors {
        // Default style
        &.checkbox-#{$name} {
            @include checkbox-solid-theme(
                get($checkbox-config, color),
                $color,
                $white
            );
        }

        // Light style
        &.checkbox-light-#{$name} {
            @include checkbox-solid-theme(
                theme-light-color($name),
                $color,
                theme-inverse-color($name)
            );
        }

        // Outline style
        &.checkbox-outline.checkbox-#{$name} {
            @include checkbox-outline-theme(
                get($checkbox-config, types, outline, theme, base-color),
                $color,
                $color
            );
        }
    }
}

// Inline checkbox
.checkbox-inline {
    display: flex;
    flex-wrap: wrap;

    .checkbox {
        margin-right: get($checkbox-config, itemSpace);
        margin-bottom: get($checkbox-config, inlineItemSpace);

        span {
            margin-right: get($checkbox-config, labelSpace);
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

// List checkbox
.checkbox-list {
    display: flex;
    flex-direction: column;

    .checkbox {
        margin-bottom: get($checkbox-config, itemSpace);

        span {
            margin-right: get($checkbox-config, labelSpace);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

// Input group
.input-group {
    .checkbox {
        margin-bottom: 0 !important;
        padding-left: 0;
    }
}

// Form integration
.form-inline {
    .checkbox {
        margin-left: 15px;
        margin-right: 15px;
    }
}
