// Theme Styles
@import './style.react.scss';

// Default Layout themes
@import './themes/layout/header/base/light.scss';
@import './themes/layout/header/menu/light.scss';
@import './themes/layout/brand/dark.scss';
@import './themes/layout/aside/dark.scss';

// Customization
@import './custom/general';
@import './custom/assistant';
@import './custom/datatable';
@import './custom/react-select';
