//
// Custom button variation
//

@mixin navi-link-theme(
    $bullet-bg,
    $text-color,
    $icon-color,
    $arrow-color,
    $link-bg,
    $link-border-bottom
) {
    @if ($bullet-bg != null) {
        .navi-bullet {
            .bullet {
                background-color: $bullet-bg;
            }
        }
    }

    @if ($text-color != null) {
        color: $text-color;

        .navi-text {
            color: $text-color;
        }
    }

    @if ($icon-color != null) {
        .navi-icon {
            // Font icon
            i {
                color: $icon-color;
            }

            // Svg icon
            @include svg-icon-color($icon-color);
        }
    }

    @if ($arrow-color != null) {
        .navi-arrow {
            color: $arrow-color;
        }
    }

    @if ($link-bg != null) {
        background-color: $link-bg;
    }

    @if ($link-border-bottom != null) {
        border-bottom: 1px solid $link-border-bottom;
    }
}

@mixin navi-icon-size($width, $font-size, $svg-size) {
    .navi-icon {
        flex: 0 0 $width;

        i {
            font-size: $font-size;
        }

        @include svg-icon-size($svg-size);
    }
}

@mixin navi-font-size($font-size) {
    font-size: $font-size;

    .navi-text {
        font-size: $font-size;
    }
}

@mixin navi-link-transition($transition) {
    transition: $transition;

    .navi-bullet {
        .bullet {
            transition: $transition;
        }
    }

    .navi-text {
        transition: $transition;
    }

    .navi-icon {
        transition: $transition;

        i {
            transition: $transition;
        }

        // Svg icon
        .svg-icon {
            @include svg-icon-transition();
        }
    }

    .navi-arrow {
        transition: $transition;
    }
}
