//
// Aside dark theme
//

// Initialization of global variables, mixins and functions
@import '../../../init';

.aside {
    background-color: get($layout-themes, dark);

    // Aside Menu
    .aside-menu {
        // Scrollbar
        @include perfect-scrollbar-theme(#637099);
    }
}

// Build aside menu theme
@include menu-ver-build-theme($aside-menu-config, dark);
