//
// Apexcharts
//

// Text
.apexcharts-text,
.apexcharts-title-text,
.apexcharts-legend-text {
    font-family: $font-family-base !important;
}

.apexcharts-title-text {
    font-weight: $font-weight-normal;
}

.apexcharts-pie-label {
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
}

// Dropdown
.apexcharts-toolbar {
    text-align: left !important;
}

.apexcharts-menu {
    background: $white;
    border: 0 !important;
    padding: 0.5rem 0 !important;
    box-shadow: $dropdown-box-shadow;
    border-radius: $border-radius !important;
    overflow: hidden;
    min-width: 10rem !important;

    .apexcharts-menu-item {
        padding: $dropdown-item-padding-y $dropdown-item-padding-x;
        transition: $transition;

        &:hover {
            background-color: $hover-bg !important;
        }
    }
}

// Tooltips
.apexcharts-tooltip {
    &.apexcharts-theme-light {
        @include border-radius($border-radius);
        box-shadow: $dropdown-box-shadow;
        border: 0 !important;
        background: $white;

        .apexcharts-tooltip-title {
            background: $gray-100 !important;
            border: 0 !important;
        }
    }

    .apexcharts-tooltip-title {
        padding: 0.5rem 1rem;
    }

    .apexcharts-tooltip-series-group {
    }
}

.apexcharts-xaxistooltip {
    &.apexcharts-theme-light {
        @include border-radius($border-radius !important);
        box-shadow: $dropdown-box-shadow !important;
        border: 0 !important;
        background: $white !important;

        &:before {
            border-bottom: 0 !important;
        }

        &:after {
            border-bottom-color: $white !important;
        }
    }
}

// Border radius integration
.card-rounded-bottom {
    .apexcharts-canvas {
        svg {
            border-bottom-left-radius: $card-border-radius;
            border-bottom-right-radius: $card-border-radius;
        }
    }
}

// Border radius options
.rounded {
    .apexcharts-canvas {
        svg {
            border-radius: $border-radius !important;
        }
    }
}

.rounded-sm {
    .apexcharts-canvas {
        svg {
            border-radius: $border-radius-sm !important;
        }
    }
}

.rounded-lg {
    .apexcharts-canvas {
        svg {
            border-radius: $border-radius-lg !important;
        }
    }
}

.rounded-xl {
    .apexcharts-canvas {
        svg {
            border-radius: $border-radius-xl !important;
        }
    }
}
