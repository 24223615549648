//
// Navi
//

// Variables

// Base
.navi {
    padding: 0;
    margin: 0;
    display: block;
    list-style: none;

    .navi-item {
        padding: 0;
        display: block;
        list-style: none;

        .navi-link {
            display: flex;
            align-items: center;
            padding: get($navi-config, link-py) get($navi-config, link-px);

            .navi-icon {
                line-height: 0;
                flex-shrink: 0;

                &:before {
                    line-height: 0;
                }
            }

            .navi-bullet {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                margin-right: get($navi-config, link-elem-space);
            }

            .navi-text {
                flex-grow: 1;
            }

            .navi-label {
                line-height: 0;
                margin-left: get($navi-config, link-elem-space);
            }

            .navi-toolbar {
                margin-left: get($navi-config, link-elem-space);
            }

            .navi-arrow {
                line-height: 0;
                margin-left: get($navi-config, link-elem-space);
                transition: $transition;
                color: $dark-50;

                &:before {
                    line-height: 0;
                    @include ki(get($ki-types, arrowNext));
                    font-size: get($navi-config, link-arrow-font-size);
                }

                &.navi-arrow-down {
                    transform: rotateZ(-90deg) #{'/*rtl:ignore*/'};

                    [direction='rtl'] & {
                        transform: rotateZ(90deg) #{'/*rtl:ignore*/'};
                    }
                }
            }
        }

        &:last-child {
            border-bottom: 0 !important;

            .navi-link {
                border-bottom: 0 !important;
            }
        }
    }

    .navi-separator {
        margin: 0;
        padding: 0;
        height: 0;
        display: block;
        border-bottom: 1px solid $border-color;

        &.navi-separator-dashed {
            border-bottom: 1px dashed $border-color;
        }
    }

    .navi-header,
    .navi-footer,
    .navi-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navi-section {
        padding: get($navi-config, section-py) get($navi-config, section-px);
    }

    .navi-header {
        padding: get($navi-config, header-py) get($navi-config, header-px);
    }

    .navi-footer {
        padding: get($navi-config, footer-py) get($navi-config, footer-px);
    }
}

// Without horizontal spacer
.navi.navi-spacer-x-0 {
    .navi-item {
        .navi-link {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// Icon Sizes
.navi {
    .navi-item {
        .navi-link {
            @include navi-icon-size(
                get($navi-config, link-icon-width),
                get($navi-config, link-icon-font-size),
                get($navi-config, link-icon-svg-size)
            );
        }
    }

    &.navi-icon-lg {
        .navi-item {
            .navi-link {
                @include navi-icon-size(
                    get($navi-config, link-icon-width-lg),
                    get($navi-config, link-icon-font-size-lg),
                    get($navi-config, link-icon-svg-size-lg)
                );
            }
        }
    }

    &.navi-icon-xl {
        .navi-item {
            .navi-link {
                @include navi-icon-size(
                    get($navi-config, link-icon-width-xl),
                    get($navi-config, link-icon-font-size-xl),
                    get($navi-config, link-icon-svg-size-xl)
                );
            }
        }
    }

    &.navi-icon-center {
        .navi-item {
            .navi-link {
                .navi-icon {
                    text-align: center;
                }
            }
        }
    }
}

// Font Sizes
.navi {
    .navi-item {
        .navi-link {
            @include navi-font-size(get($navi-config, link-text-font-size));
        }
    }

    &.navi-font-lg {
        .navi-item {
            .navi-link {
                @include navi-font-size(
                    get($navi-config, link-text-font-size-lg)
                );
            }
        }
    }

    &.navi-font-xl {
        .navi-item {
            .navi-link {
                @include navi-font-size(
                    get($navi-config, link-text-font-size-xl)
                );
            }
        }
    }
}

// Font Weight Options
.navi {
    &.navi-light {
        .navi-item {
            .navi-link {
                .navi-text {
                    font-weight: $font-weight-light;
                }
            }
        }
    }

    &.navi-lighter {
        .navi-item {
            .navi-link {
                .navi-text {
                    font-weight: $font-weight-lighter;
                }
            }
        }
    }

    &.navi-bold {
        .navi-item {
            .navi-link {
                .navi-text {
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    &.navi-bolder {
        .navi-item {
            .navi-link {
                .navi-text {
                    font-weight: $font-weight-bolder;
                }
            }
        }
    }

    &.navi-boldest {
        .navi-item {
            .navi-link {
                .navi-text {
                    font-weight: $font-weight-boldest;
                }
            }
        }
    }
}

// Default Theme
.navi {
    .navi-item {
        .navi-link {
            // Default transitions
            @include navi-link-transition($transition);

            // Default state
            @include navi-link-theme(
                get($navi-config, link-bullet-bg),
                get($navi-config, link-text-color),
                get($navi-config, link-icon-color),
                get($navi-config, link-arrow-color),
                null,
                null
            );

            // Disabled state
            &.disabled {
                opacity: get($navi-config, link-opacity-disabled);
            }

            &:hover {
                @include navi-link-transition($transition);
                @include navi-link-theme(
                    get($navi-config, link-bullet-bg-hover),
                    get($navi-config, link-text-color-hover),
                    get($navi-config, link-icon-color-hover),
                    get($navi-config, link-arrow-color-hover),
                    null,
                    null
                );
            }

            &.active {
                @include navi-link-transition($transition);
                @include navi-link-theme(
                    get($navi-config, link-bullet-bg-active),
                    get($navi-config, link-text-color-active),
                    get($navi-config, link-icon-color-active),
                    get($navi-config, link-arrow-color-active),
                    null,
                    null
                );
            }
        }
    }
}

// Light Icon Theme
.navi.navi-light-icon {
    .navi-item {
        .navi-link {
            // Default transitions
            @include navi-link-transition($transition);

            // Default state
            @include navi-link-theme(
                get($navi-config, link-bullet-bg),
                get($navi-config, link-text-color),
                get($navi-config, link-icon-light-color),
                get($navi-config, link-arrow-color),
                null,
                null
            );

            // Disabled state
            &.disabled {
                opacity: get($navi-config, link-opacity-disabled);
            }

            &:hover {
                @include navi-link-transition($transition);
                @include navi-link-theme(
                    get($navi-config, link-bullet-bg-hover),
                    get($navi-config, link-text-color-hover),
                    get($navi-config, link-icon-color-hover),
                    get($navi-config, link-arrow-color-hover),
                    null,
                    null
                );
            }

            &.active {
                @include navi-link-transition($transition);
                @include navi-link-theme(
                    get($navi-config, link-bullet-bg-active),
                    get($navi-config, link-text-color-active),
                    get($navi-config, link-icon-color-active),
                    get($navi-config, link-arrow-color-active),
                    null,
                    null
                );
            }
        }
    }
}

// Border Style
.navi {
    &.navi-border {
        .navi-item {
            .navi-link {
                border-bottom: 1px solid $border-color;
            }
        }
    }
}

// Border Radius
.navi {
    &.navi-link-rounded {
        .navi-item {
            .navi-link {
                border-radius: $border-radius;
            }
        }
    }

    &.navi-link-rounded-lg {
        .navi-item {
            .navi-link {
                border-radius: $border-radius-lg;
            }
        }
    }
}

// Background Color Style
.navi {
    &.navi-hover {
        .navi-item {
            .navi-link:hover {
                background-color: get($navi-config, link-bg-hover);
            }
        }
    }

    &.navi-active {
        .navi-item {
            .navi-link.active {
                background-color: get($navi-config, link-bg-active);
            }
        }
    }
}

// Accent Style
.navi {
    &.navi-accent {
        .navi-item {
            .navi-link {
                position: relative;

                &:before {
                    display: block;
                    left: 0;
                    top: 8px;
                    bottom: 8px;
                    position: absolute;
                    width: 4px;
                    background-color: transparent;
                    transition: $transition;
                    @include border-radius(2px);
                    content: ' ';
                }

                &.active {
                    &:before {
                        background-color: $primary;
                        transition: $transition;
                    }
                }
            }
        }

        // Theme colors
        @each $name, $color in $theme-colors {
            &.navi-#{$name} {
                .navi-item {
                    .navi-link {
                        &:hover {
                            @include navi-link-theme(
                                null,
                                $color,
                                $color,
                                $color,
                                null,
                                null
                            );
                        }

                        &.active {
                            @include navi-link-theme(
                                null,
                                $color,
                                $color,
                                $color,
                                null,
                                null
                            );

                            &:before {
                                background-color: $color;
                            }
                        }
                    }
                }
            }
        }
    }
}
