//
// Pagination
//

.pagination {
    flex: 1;
    display: flex;

    .page-item {
        padding: 0;
        margin-right: 5px;
        display: inline-block;

        &.active .page-link {
            z-index: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &.page-item-first,
        &.page-item-prev,
        &.page-item-next,
        &.page-item-last {
            .page-link {
                background-color: get($datatable-config, page-bg);
            }
        }

        &.disabled {
            opacity: 0.6;
            cursor: default;
        }

        &:not(.disabled) {
            .page-link:hover {
                opacity: 1;
                transition: $transition-link;
                background-color: get($datatable-config, page-bg-hover);
                color: get($datatable-config, page-color-hover);

                i {
                    transition: $transition-link;
                    color: get($datatable-config, page-icon-color-hover);
                }
            }
        }

        &.page-item-active {
            .page-link {
                transition: $transition-link;
                background-color: get($datatable-config, page-bg-active);
                color: get($datatable-config, page-color-active);

                i {
                    transition: $transition-link;
                    color: get($datatable-config, page-icon-color-active);
                }
            }
        }
    }

    .page-link {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: $transition-link;
        height: get($datatable-config, page-size);
        min-width: get($datatable-config, page-size);
        padding: 0.5rem;
        position: relative;
        font-size: 0.9rem;
        line-height: 1rem;
        font-weight: 500;
        @include border-radius($border-radius);
        border-width: 0;

        &.active,
        &:hover,
        &:focus {
            .page-link {
                transition: $transition-link;
            }
        }

        color: get($datatable-config, page-color);

        i {
            font-size: 0.6rem;
            text-align: center;
            display: inline-block;
            line-height: 0;
            color: get($datatable-config, page-icon-color);
            transition: $transition-link;
        }
    }
}
