.root {
    height: 100%;
    display: flex;
}

.brand {
    background-color: #1e1e2d;

    .brand-logo {
        width: 180px;

        svg {
            path {
                fill: #ffffff !important;
            }
        }
    }
}

.select-location {
    width: 250px;
}

.dropdown {
    .dropdown-item {
        .fa-icon {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

.select-location-modal {
    h4 {
        margin-bottom: 5px;
    }

    .list-group {
        height: 400px;
        margin-bottom: 10px;
        overflow: scroll;
    }

    @include media-breakpoint-down(xs) {
        .btn-connect {
            display: block;
            width: 100%;
        }
    }

    @include media-breakpoint-up(sm) {
        .btn-connect {
            float: right;
            min-width: 150px;
        }
    }

    .input-icon {
        width: 100%;

        input {
            width: 100%;
        }
    }

    .form-group {
        width: 100%;
    }

    .form-group-select-status {
        select {
            float: right;
        }
    }
}

.menu-nav {
    .menu-btn-item {
        padding: 9px 25px;
    }

    .menu-item {
        &.organization {
            h1,
            h2,
            h3,
            h4 {
                color: $white;
            }
        }
    }
}

.card {
    .card-body {
        .card-buttons {
            margin-top: $spacer * 2;

            button {
                margin-left: $spacer;
                min-width: 125px;
            }
        }
    }
}
